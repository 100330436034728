import React from 'react';
import { Formik, FormikProps } from 'formik';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import TextInput from '@components/app/form/TextInput';
import CountryCombobox from '@components/app/form/select/CountryCombobox';
import FormikDownshiftSelect from '@components/app/form/select/FormikDownshiftSelect';
import { isEmail } from 'validator';
import { ComboboxItem } from '@components/app/form/select/downshift/types';
import { unfinishedSignUp } from '@/api/user';
import mapApiErrorsToFormikErrors from '@/form/mapApiErrorsToFormikErrors';
import { Country } from '@/types/core/Country';
import { ApiException } from '@/types/exception/ApiException';
import LoginModal from '@components/LoginModal';

interface FormValues {
  email: string;
  password: string;
  country: ComboboxItem | null;
}

function FormikForm({
  submitCount,
  isValid,
  isValidating,
  isSubmitting,
  errors,
  handleSubmit,
  values,
}: FormikProps<FormValues>) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <form
        onSubmit={evt => {
          evt.preventDefault();
          handleSubmit(evt);
        }}
        noValidate
      >
        <Box>
          <VStack
            spacing="1.5rem"
            mt={{ base: '2rem', md: '-1rem' }}
            mb={{ base: '2rem', md: '1rem' }}
          >
            <FormControl>
              <FormLabel fontWeight="600" fontSize="1.125rem">
                Pays *
              </FormLabel>
              <FormikDownshiftSelect
                name={'country'}
                SelectComponent={CountryCombobox}
                id={'country'}
                fields={{ country: 'name,code' }}
                orders={['name']}
                required
                requiredMessage={'Pays requis'}
                size={'lg'}
                fontSize=".8125rem"
                isRequiredIndicatorNotDisplayed
              />
            </FormControl>
            <TextInput
              label="Adresse email *"
              placeholder="Saisissez votre adresse email"
              name="email"
              lineHeight="3.125rem"
              fontSize=".8125rem"
              height="auto"
              required
              isRequiredIndicatorNotDisplayed
              isHomePageLabel
            />
            <TextInput
              label="Mot de passe *"
              name="password"
              type={'password'}
              lineHeight="3.125rem"
              fontSize=".8125rem"
              height="auto"
              required
              isRequiredIndicatorNotDisplayed
              isHomePageLabel
            />
            <Text fontSize=".75rem" mb="1em">
              En cliquant sur Accepter et s'inscrire, vous acceptez les{' '}
              <Link as={NextLink} textDecoration="underline" href="#">
                Conditions d'utilisation
              </Link>
              , la{' '}
              <Link as={NextLink} textDecoration="underline" href="#">
                Politique de conﬁdentialité
              </Link>{' '}
              et la{' '}
              <Link as={NextLink} textDecoration="underline" href="#">
                Politique relative aux cookies
              </Link>{' '}
              d’IFprofs.
            </Text>
          </VStack>
          <VStack spacing={{ base: '2rem', md: '3.25rem' }}>
            <Button
              width={{ base: '100%', sm: 'revert' }}
              py="1rem"
              height="auto"
              type={'submit'}
              isLoading={isSubmitting}
            >
              <Text as="span" fontSize="1.25rem">
                Accepter et s'inscrire
              </Text>
            </Button>
            <Flex
              direction={{ base: 'column', lg: 'row' }}
              alignItems={{ lg: 'center' }}
              gap={{ md: '1rem' }}
            >
              <Text textAlign="center" mb={{ base: '1em', lg: 0 }}>
                Vous avez déjà un compte ?
              </Text>
              <Button
                width={{ base: '100%', md: 'revert' }}
                py="1em"
                variant="whiteOutline"
                onClick={onOpen}
              >
                <Text as="span" lineHeight={{ base: '1', sm: null }}>
                  Se connecter
                </Text>
              </Button>
            </Flex>
          </VStack>
        </Box>
      </form>
      {isOpen && (
        <LoginModal
          onClose={onClose}
          onLoginSuccess={user => {
            toast({
              title: `Bienvenue ${user?.displayName}`,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      )}
    </>
  );
}

const normalizeFormData = (values: FormValues) => {
  return {
    email: values.email,
    password: values.password,
    countryId: values.country?.id,
    captcha: 5,
  };
};
export default function RegisterHomeForm({
  onSubmitEnd,
}: {
  onSubmitEnd?: (idUnfinishedSignup: string, country: Country) => any;
}) {
  const initialValues: FormValues = {
    email: '',
    password: '',
    country: null,
  };
  const toast = useToast();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (data, actions) => {
        const country = data.country.item as Country;

        let response: Response;
        try {
          response = await unfinishedSignUp(normalizeFormData(data));
        } catch (err) {
          toast({
            title: 'Une erreur est survenue',
            description:
              "Une erreur est survenue lors de l'inscription. Veuillez réessayer ultérieurement.",
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          actions.setSubmitting(false);
          return;
        }
        if (response.ok) {
          const responseData = await response.json();
          if (onSubmitEnd) {
            onSubmitEnd(responseData.data.id, country);
            return;
          }
        }
        if (
          response.status === 400 &&
          response.headers.get('Content-Type') === 'json+errors'
        ) {
          const formErrors: ApiException = await response.json();
          actions.setErrors(mapApiErrorsToFormikErrors(formErrors));
        }
        actions.setSubmitting(false);
      }}
      validate={data => {
        let errors: {
          password?: string[];
          email?: string[];
        } = {};

        if (data.password && !data.password.match(/^.{8,}$/)) {
          errors.password = [
            'Le mot de passe doit contenir au minimum 8 caractéres',
          ];
        }

        if (data.email && !isEmail(data.email)) {
          errors.email = ["Cette valeur n'est pas une adresse email valide"];
        }

        return errors;
      }}
    >
      {formProps => {
        return <FormikForm {...formProps} />;
      }}
    </Formik>
  );
}
